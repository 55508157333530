import "./App.css";

function App() {
  return (
    <div className="page">
      <div className="emoji">👀</div>
      <h1 className="pulsate">Coming soon...</h1>
    </div>
  );
}

export default App;
